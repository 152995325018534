button.active {
  visibility: visible;
}

.green {
  background-color: #adff2f;
}

@font-face {
  font-family: customfont;
  src: url("Bruce.6168461e.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, input, button {
  font-family: customfont;
}

main {
  background: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  place-items: center;
  min-width: 100vw;
  min-height: 100vh;
  display: grid;
}

.heading {
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 400;
  position: relative;
}

.playerstatus {
  border: 1px solid #000;
  border-radius: 50px;
  padding: 15px;
  font-size: 1.5rem;
  box-shadow: 5px 5px 5px #111;
}

.current-player {
  color: red;
}

.heading:before {
  content: "X";
  color: red;
  font-weight: 400;
  position: absolute;
  left: -3rem;
}

.heading:after {
  content: "O";
  color: red;
  font-weight: 400;
  position: absolute;
  right: -3rem;
}

.grid {
  aspect-ratio: 1;
  border-radius: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 20rem;
  display: grid;
  box-shadow: 12px 12px 16px #54565e;
}

.box {
  aspect-ratio: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: varela round;
  font-size: 3.8rem;
  font-weight: 700;
  display: flex;
}

.box:nth-child(2n) {
  border: 1px solid #dbdbdb;
}

button {
  cursor: pointer;
  visibility: hidden;
  border-radius: 1rem;
  padding: .5rem 3rem;
}

button:hover {
  color: #fff;
  background-color: #000;
}
/*# sourceMappingURL=index.852d6565.css.map */
